import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import { TextField } from "@mui/material";
import React from "react";

export const GridVulnerabilityFlightHeightsForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <>
      <GridVulnerabilityFlightHeightsAverageForm name={name} />
    </>
  );
};

export const GridVulnerabilityFlightHeightsAverageForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] = useField<{
    type: "average";
    percentAtRotorHeight?: number | string;
  }>(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    // <CommonPaper>
    <TextField
      margin={"dense"}
      size={"small"}
      error={
        !!(
          (touched[`${name}.percentAtRotorHeight`] || submitCount) &&
          errors?.percentAtRotorHeight
        )
      }
      value={config?.percentAtRotorHeight || null}
      onChange={({ target: { value } }) =>
        setConfig({
          type: "average",
          percentAtRotorHeight: value,
        })
      }
      label={
        strings[
          "grid-vulnerability.points.flight_heights.average.percentAtRotorHeight"
        ]
      }
    />
    // </CommonPaper>
  );
};
