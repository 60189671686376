import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import React from "react";
import { FormTextField } from "@/components/common/form-field";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
const defaultConfig = {
  blades: 3,
  radius: 60.5,
  pitch: 15,
  chord: 4.21,
  omega: 5,
  numberOfTurbines: 22,
  hubheight: 90,
  offset: 0,
  timeOfWorkPerMonth: [
    0.75, 0.8, 0.83, 0.76, 0.7, 0.67, 0.69, 0.7, 0.8, 0.83, 0.8, 0.78,
  ],
  bladeForm: [
    ["0.05", "0.73"],
    ["0.1", "0.79"],
    ["0.15", "0.88"],
    ["0.2", "0.96"],
    ["0.25", "1"],
    ["0.3", "0.98"],
    ["0.35", "0.92"],
    ["0.4", "0.85"],
    ["0.45", "0.8"],
    ["0.5", "0.75"],
    ["0.55", "0.7"],
    ["0.6", "0.64"],
    ["0.65", "0.58"],
    ["0.7", "0.52"],
    ["0.75", "0.47"],
    ["0.8", "0.41"],
    ["0.85", "0.37"],
    ["0.9", "0.3"],
    ["0.95", "0.24"],
    ["1", "0"],
  ],
};
export const GridVulnerabilityWindfarmConfigForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <Accordion className={"common__card_blue"}>
      <AccordionSummary>
        <Typography>{strings["grid-vulnerability.windfarm"]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Button onClick={() => setConfig(defaultConfig)}>
          {strings["grid-vulnerability.windfarm.set-default"]}
        </Button>
        <FormTextField
          name={`${name}.blades`}
          label={strings["grid-vulnerability.windfarm.blades"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.radius`}
          label={strings["grid-vulnerability.windfarm.radius"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.pitch`}
          label={strings["grid-vulnerability.windfarm.pitch"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.chord`}
          label={strings["grid-vulnerability.windfarm.chord"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.bladeForm`}
          label={strings["grid-vulnerability.windfarm.bladeForm.radius"]}
          getValue={(value) => value?.map((it: any) => it[0]).join(",") || ""}
          mapValue={(value) => {
            const res = value?.split(",").map((it) => it.trim()) || [];
            return res.map((it, index) => {
              return [it, config?.bladeForm?.[index]?.[1] || 0];
            });
          }}
        />
        <FormTextField
          name={`${name}.bladeForm`}
          label={strings["grid-vulnerability.windfarm.bladeForm.chords"]}
          getValue={(value) => value?.map((it: any) => it[1]).join(",") || ""}
          mapValue={(value) => {
            const res = value?.split(",").map((it) => it.trim()) || [];

            return config?.bladeForm?.map((it: any, index: number) => {
              return [it[0], res[index] ?? 0];
            });
          }}
        />
        <FormTextField
          name={`${name}.omega`}
          label={strings["grid-vulnerability.windfarm.omega"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.numberOfTurbines`}
          label={strings["grid-vulnerability.windfarm.numberOfTurbines"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.hubheight`}
          label={strings["grid-vulnerability.windfarm.hubheight"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.offset`}
          label={strings["grid-vulnerability.windfarm.offset"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.timeOfWorkPerMonth`}
          label={strings["grid-vulnerability.windfarm.timeOfWorkPerMonth"]}
          getValue={(value) => value?.map((it: any) => it).join(",") || ""}
          mapValue={(value) => {
            const res = value?.split(",").map((it) => it.trim()) || [];
            return res;
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
