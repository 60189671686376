import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import { GisHabitatAreaVulnerabilityCalculationConfig } from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import { MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { useVulnerabilityGridsSwr } from "@/features/analysis/vulnerability/components/grid/swr";
import { CommonPaper } from "@/components/common/common";

export const GridVulnerabilityGridForm = ({ name }: { name: string }) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField<GisHabitatAreaVulnerabilityCalculationConfig["grid"]>(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <CommonPaper>
      <GridVulnerabilityGridMapForm name={name} />
    </CommonPaper>
  );
};

export const GridVulnerabilityGridMapForm = ({ name }: { name: string }) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] = useField<{
    type: "map";
    id?: number;
  }>(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  const { data } = useVulnerabilityGridsSwr();
  return (
    <>
      <Typography>{strings["grid-vulnerability.grid.map"]}</Typography>
      <Select
        onClick={(e) => e.stopPropagation()}
        size={"small"}
        value={config?.id}
        onChange={({ target: { value } }) => {
          setConfig({ type: "map", id: Number(value) });
        }}
      >
        {data?.results.map((grid) => (
          <MenuItem key={grid.id} value={grid.id}>
            {grid.title}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
