import { getPluralEn } from "./plural/en";

export const enTranslations = {
  "top-menu.instruction": "Instruction",
  "top-menu.license": "License",
  "top-menu.license-text":
    "The program is distributed under the CC BY-ND 4.0 license\n" +
    'Citation: Knizhov K.I., Karyakin I.V. BioDiv - a program for modelling distribution, population numbers, survival and risk of collision of birds with wind turbines. GIS portal of the project "Eagles of the Palearctic". 2024. URL: https://gis.eaglesofthepalearctic.org',

  "common.advanced-settings": "Advanced settings",
  "common.edit": "Edit",
  "common.delete": "Delete",
  "common.dates": "Dates",
  "common.close": "Close",
  "common.clear": "Clear",
  "common.copy": "Copy",
  "common.copied": "Copied",

  "common.save": "Save",
  "common.hide": "Hide",
  "common.show": "Show",
  "common.cancel": "Cancel",
  "common.objects-plural": (number: number) =>
    getPluralEn({ number, key: "objects" }),
  "geometry.input-at-map": "Set geometry on the map",
  "data-extraction.add-data": "Add data",
  "data-extraction.title": "Data export",
  "data-extraction.get-result": "Run",
  "data-extraction.add-dates-to-result": "Add dates to result",
  "data-extraction.choose-params": "Add params",
  "data-extraction.choose-points": "Add points",
  "data-extraction.choose-random-points": "Add random points (optional)",
  "data-extraction.choose-background-points":
    "Add background points (optional)",
  "data-extraction.choose-background-points-percent-inside-buffers":
    "Percent inside buffers",
  "data-extraction.choose-background-points-region": "Add region of interest",
  "data-extraction.copy-config": "Copy config",
  "data-extraction.import-config": "Import config",

  "data-selection.title": "Data selection",
  "data-selection.select.normal-test": "Normal test",
  "data-selection.select.t-test": "T-test",
  "data-selection.select.correlation": "Correlation",
  "data-selection.select.moran-test": "Moran test",

  "data-selection.add-data": "Add data",
  "data-selection.all-data": "Combine presence/absence",
  "data-selection.separate-data": "Separate presence/absence",
  "data-selection.choose-all-data": "Add data",
  "data-selection.choose-presence": "Add presence data",
  "data-selection.choose-absence": "Add absence data",
  "data-selection.presence_key": "Presence column",
  "data-selection.normal.add-t-test": "Add T-test results",
  "data-selection.correlation.threshold-label": "Correlation threshold",
  "data-selection.moran.areas": "Add surveyed areas",
  "data-selection.moran.points": "Add points",

  "random-forest.title": "Random forest",
  "random-forest.choose-training-points": "Training points",
  "random-forest.choose-all-training-points": "Set training points",
  "random-forest.choose-presence": "Presence points",
  "random-forest.choose-output-mode": "Classification mode",
  "random-forest.choose-absence": "Absence points",
  "random-forest.choose-region": "Add  region of interest",
  "random-forest.all-training-points": "All points",
  "random-forest.separate-training-points": "Separate points",
  "random-forest.post-process": "Post process images",
  "random-forest.buffersPerAreaPoint":
    "Buffers around discrete classificated images",
  "random-forest.classificationSplits": "Split classificated images by percent",
  "script-input.scale": "scale",
  "script-input.buffer": "buffer",
  "script-input.filename": "filename",
  "script-input.bands": "bands",
  "population.title": "Estimate population",
  "population.choose-type": "Estimation type",
  "population.random-generation": "Random points generation",
  "population.distance": "Distance",
  "population.density": "Census on plots",
  "population.observed-areas": "Observed areas",
  "population.presence-points": "Presence points",
  "population.presence-area": "Presence area",
  "population.cross-validation": "Cross validation",
  "population.seed": "Random seed",
  "population.use-random-forest": "Use random forest",
  "population.distance-file": "File for distance",
  "population.distance-function": "Key function",
  "population.density-file": "Plots file",
  "population.distance-total-area": "Total area",
  "random-forest.validation": "Validation",
  "random-forest.validation.split": "Split percent",
  "random-forest.validation.seed": "Random seed",
  "random-forest.validation.split-points": "Split training points",
  "random-forest.choose-validation-points": "Validation points",
  "random-forest.validation.external": "External points",
  "random-forest.validation.cross_validation": "Cross validation",
  "random-forest.validation.render_mean": "Render mean",
  "random-forest.validation.render_best": "Render best",
  "random-forest.validation.use-by-default": "Use by default",
  "random-forest.validation.best": "Best",
  "random-forest.validation.mean": "Mean",
  "survival.title": "Survival",
  "survival.file": "File for MARK nest survival",
  "survival.nocc": "Number of occasions",
  "maxent.title": "Maxent",
  "maxent.background_points": "Background points",
  "maxent.generate_background_points": "Generate background points",
  "maxent.background_points_count": "Background points count",

  "migrations.title": "Migrations",
  "migrations.add-files": "Add track files",
  "migrations.add-migration": "Add migration",
  "migrations.add-season": "Add",

  "migrations.auto-migration": "Auto find migrations",
  "migrations.parse-files": "parse",
  "migrations.winter": "Winter",
  "migrations.autumn": "Autumn",
  "migrations.summer": "Summer",
  "migrations.spring": "Spring",
  "migrations.selected-migrations": "Selected migrations",

  "migrations.generate": "generate",
  "migrations.generate-count-label": "Generate tracks count",
  "migrations.generated-tracks-total": "Tracks",

  "migrations.generated-tracks": "Generated tracks",
  "migrations.generated-areas": "Generated areas",
  "migrations.indexed-areas": "Indexed areas",
  "migrations.vulnerability": "Vulnerability",
  "migrations.vulnerability-config": "config",
  "migrations.vulnerability-errors-in-config": "errors in config",
  "migrations.vulnerability-run": "run",
  "migrations.area-use-in-vulnerability": "use in vulnerability",
  "migrations.area-statistics": "details",
  "migrations.area-index": "index",
  "migrations.area-real-tracks": "uniq flights",
  "migrations.area-total-tracks": "generated tracks",

  "migrations.point-index": "index",
  "migrations.point-date": "date",
  "migrations.point-altitude": "altitude",
  "migrations.point-info": "Tracker info",
  "migrations.vulnerability-selecred-areas": "Selected areas",

  "migrations.area-heights": "Altitude (m)",
  "migrations.area-real-heights": "Real points",
  "migrations.area-generated-heights": "Generated points",
  "migrations.add-habitat-areas": "Add habitat areas",

  "grid-vulnerability.title": "Vulnerability",
  "grid-vulnerability.grid.map": "Select grid",
  "grid-vulnerability.density": "Density",
  "grid-vulnerability.density.type": "Select Type",
  "grid-vulnerability.density.simple-area": "Habitat",
  "grid-vulnerability.density.simple-area.presence_area": "Select Habitat",
  "grid-vulnerability.density.points": "Points",
  "grid-vulnerability.density.points.points": "Points",
  "grid-vulnerability.density.points.outside_buffers_count":
    "Birds Outside Buffers",
  "grid-vulnerability.density.points.default_buffer": "Default Buffer",
  "grid-vulnerability.density.points.presence_area": "Habitat Area",
  "grid-vulnerability.density.points.count": "Population",
  "grid-vulnerability.bird-flight": "Bird flight data",
  "grid-vulnerability.points.flight_heights.average.percentAtRotorHeight":
    "Percentage at Rotor Height",

  "grid-vulnerability.points.bird_speed.average.speed": "Average Speed",

  "grid-vulnerability.points.counts.table": "From Table",
  "grid-vulnerability.points.counts.breeding": "By Breeding",
  "grid-vulnerability.points.counts.breeding.breeding_pairs": "Breeding Pairs",
  "grid-vulnerability.points.counts.breeding.successful_pairs":
    "Successful Pairs",
  "grid-vulnerability.points.counts.breeding.juvenils_per_pair":
    "Juveniles per Pair",
  "grid-vulnerability.points.counts.breeding.subadults_ratio":
    "Subadults Ratio",
  "grid-vulnerability.points.counts.breeding.percent_per_month":
    "Proportion per Month",
  "grid-vulnerability.windfarm.set-default": "Set default values",
  "grid-vulnerability.windfarm": "Wind Farm Data",
  "grid-vulnerability.windfarm.blades": "blades",
  "grid-vulnerability.windfarm.radius": "radius",
  "grid-vulnerability.windfarm.pitch": "pitch",
  "grid-vulnerability.windfarm.chord": "chord",
  "grid-vulnerability.windfarm.bladeForm": "Blade Shape",
  "grid-vulnerability.windfarm.bladeForm.radius": "Blade Shape Radius",
  "grid-vulnerability.windfarm.bladeForm.chords": "Blade Shape Chords",
  "grid-vulnerability.windfarm.omega": "omega",
  "grid-vulnerability.windfarm.numberOfTurbines": "numberOfTurbines",
  "grid-vulnerability.windfarm.hubheight": "hubheight",
  "grid-vulnerability.windfarm.offset": "offset",
  "grid-vulnerability.windfarm.timeOfWorkPerMonth": "Time of work per month",

  "grid-vulnerability.bird_config": "Bird Data",
  "grid-vulnerability.bird_config.length": "Body Length",
  "grid-vulnerability.bird_config.wingspan": "Wingspan",
  "grid-vulnerability.bird_config.nocturnalActivity": "Nocturnal Activity",

  "grid-vulnerability.bird_config.flight_type": "Flight Type",
  "grid-vulnerability.bird_config.flight_type.flapping": "Flapping",
  "grid-vulnerability.bird_config.flight_type.gliding": "Gliding",

  "grid-vulnerability.flight_locations": "Flight Times",
  "grid-vulnerability.flight_locations.average": "Average",
  "grid-vulnerability.flight_locations.average.lightDayPercent":
    "Percentage of Activity from Daylight",

  "registration.title": "Registration",
  "registration.email-label": "Email",
  "registration.name-label": "Name",
  "registration.name-placeholder": "Enter your full name",
  "registration.password-label": "Password",
  "registration.password-placeholder": "Minimum 8 symbols",
  "registration.password-repeat-placeholder": "Repeat password",
  "registration.password_repeat-label": "Repeat password",
  "registration.comment-label": "Additional info",
  "registration.comment-placeholder": "For example, additional contact info",
  "registration.register-button": "Sign up",
  "registration.login-link": "Sign in",

  "login.title": "Sign in",
  "login.password-placeholder": "Enter your password",
  "login.login-button": "Sign in",
  "login.registration-link": "Sign up",

  "cabinet.navigation-info": "Info",
  "cabinet.navigation-results": "Results",
  "cabinet.navigation-logout": "Logout",

  "cabinet-results.download": "Download",
  "cabinet-results.view-logs": "View logs",
  "cabinet-results.expires-in": "Expires:  ",
  "cabinet-results.non-expires": "Not expires",
};
