import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import { TextField } from "@mui/material";
import React from "react";

export const GridVulnerabilitySpeedForm = ({ name }: { name: string }) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    // <CommonPaper>
    <GridVulnerabilitySpeedAverageForm name={name} />
    // </CommonPaper>
  );
};
export const GridVulnerabilitySpeedAverageForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] = useField<{
    type: "average";
    speed?: number | string;
  }>(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <>
      <TextField
        margin={"dense"}
        size={"small"}
        error={!!((touched[`${name}.speed`] || submitCount) && errors?.speed)}
        value={config?.speed || null}
        onChange={({ target: { value } }) =>
          setConfig({
            type: "average",
            speed: value,
          })
        }
        label={strings["grid-vulnerability.points.bird_speed.average.speed"]}
      />
    </>
  );
};
