import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "@/store";
import { userReduxApi } from "@/store/user";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.console.warn = () => {};
store.dispatch(userReduxApi.endpoints.loadUser.initiate({}));
root.render(
  <>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { FormTextField } from "@/components/common/form-field";
