import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import { FormTextField } from "@/components/common/form-field";
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

export const GridVulnerabilityBirdConfigForm = ({ name }: { name: string }) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <Accordion className={"common__card_blue"}>
      <AccordionSummary>
        <Typography>{strings["grid-vulnerability.bird_config"]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormTextField
          name={`${name}.length`}
          label={strings["grid-vulnerability.bird_config.length"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.wingspan`}
          label={strings["grid-vulnerability.bird_config.wingspan"]}
          mapValue={(value) => value}
        />
        <FormTextField
          name={`${name}.nocturnalActivity`}
          label={strings["grid-vulnerability.bird_config.nocturnalActivity"]}
          mapValue={(value) => value}
        />
        <Typography>
          {strings["grid-vulnerability.bird_config.flight_type"]}
        </Typography>
        <Select
          onClick={(e) => e.stopPropagation()}
          size={"small"}
          value={config?.flight_type}
          onChange={({ target: { value } }) =>
            setConfig({
              ...config,
              flight_type: value as "flapping" | "gliding",
            })
          }
        >
          <MenuItem value={"flapping"}>
            {strings["grid-vulnerability.bird_config.flight_type.flapping"]}
          </MenuItem>
          <MenuItem value={"gliding"}>
            {strings["grid-vulnerability.bird_config.flight_type.gliding"]}
          </MenuItem>
        </Select>
      </AccordionDetails>
    </Accordion>
  );
};
