import { getPluralRu } from "./plural/ru";

export const ruTranslations = {
  "top-menu.instruction": "Инструкция",
  "top-menu.license": "Лицензия",
  "top-menu.license-text":
    "Программа распространяется в соответствии с лицензией CC BY-ND 4.0\n" +
    'Цитирование: Книжов К.И., Карякин И.В. BioDiv - программа для моделированию распространения, численности, выживаемости и риска столкновения птиц с лопастями ветровых турбин. ГИС-портал проекта "Орлы Палеарктики". 2024. URL: https://gis.eaglesofthepalearctic.org ',

  "common.advanced-settings": "Расширенные настройки",
  "common.dates": "Даты",
  "common.delete": "Удалить",
  "common.edit": "Редактировать",
  "common.clear": "Очистить",
  "common.copy": "Скопировать",
  "common.copied": "Скопировано",
  "common.save": "Сохранить",
  "common.hide": "Скрыть",
  "common.show": "Показать",
  "common.objects-plural": (number: number) =>
    getPluralRu({ number, key: "objects" }),
  "common.close": "Закрыть",
  "common.cancel": "Отмена",
  "geometry.input-at-map": "Отметьте геометрию на карте",
  "data-extraction.title": "Экспорт данных",
  "data-extraction.add-data": "Добавить данные",
  "data-extraction.get-result": "Пуск",
  "data-extraction.add-dates-to-result": "Добавить даты в результат",
  "data-extraction.choose-params": "Добавить параметры",
  "data-extraction.choose-points": "Добавить точки",
  "data-extraction.choose-random-points":
    "Добавить случайные точки (опционально)",
  "data-extraction.choose-background-points":
    "Добавить фоновые точки (опционально)",
  "data-extraction.choose-background-points-percent-inside-buffers":
    "Процент фоновых точек внутри буфферов ",
  "data-extraction.choose-background-points-region": "Введите область интереса",
  "data-extraction.copy-config": "Копировать кофниг",
  "data-extraction.import-config": "Вставить конфиг",

  "data-selection.title": "Выбор данных",
  "data-selection.select.normal-test": "Нормальность",
  "data-selection.select.t-test": "Т тест",
  "data-selection.select.correlation": "Корреляция",
  "data-selection.select.moran-test": "Тест Морана",

  "data-selection.add-data": "Добавить данные",
  "data-selection.all-data": "Совместить присутствие/отсутствие",
  "data-selection.separate-data": "Разделить присутствие/отсутствие",
  "data-selection.choose-all-data": "Добавить данные",
  "data-selection.choose-presence": "Добавить данные пристуствия",
  "data-selection.choose-absence": "Добавить данные отсутствия",
  "data-selection.presence_key": "Столбец пристутствия",
  "data-selection.normal.add-t-test": "Добавить результаты Т-теста",
  "data-selection.correlation.threshold-label": "Порог корреляции",
  "data-selection.moran.areas": "Добавить обследованные площадки",
  "data-selection.moran.points": "Добавить точки",

  "random-forest.title": "Random forest",
  "random-forest.choose-training-points": "Обучающий набор",
  "random-forest.choose-all-training-points": "Добавить обучающий набор",
  "random-forest.choose-presence": "Добавить точки присутствия",
  "random-forest.choose-absence": "Добавить точки отсутствия",
  "random-forest.choose-region": "Выберите область интереса",
  "random-forest.choose-output-mode": "Выберите способ классификации",
  "random-forest.all-training-points": "В одном наборе",
  "random-forest.separate-training-points": "Раздельные наборы",
  "random-forest.post-process": "Пост обработка итоговых растров",
  "random-forest.buffersPerAreaPoint":
    "Построить буфферы вокруг дискретных растров",
  "random-forest.classificationSplits": "Вывести срезы растров по процентам",
  "script-input.scale": "масштаб (scale)",
  "script-input.bands": "Слои(bands)",
  "script-input.buffer": "буффер (buffer)",
  "script-input.filename": "Имя фалйа (filename)",
  "population.title": "Оценка численности",
  "population.choose-type": "Способ оценки",
  "population.random-generation": "Генерация случайных точек",
  "population.distance": "Distance",
  "population.density": "Площадочный учет",
  "population.observed-areas": "Обследованные области",
  "population.presence-points": "Точки присутствия",
  "population.presence-area": "Область присутствия",
  "population.cross-validation": "Количество прогонов кросс валидации",
  "population.seed": "Случайное зерно",
  "population.use-random-forest": "Использовать результат random forest",
  "population.distance-file": "Файл для distance",
  "population.distance-function": "Функция плотности",
  "population.density-file": "Файл пдощадок",
  "population.distance-total-area": "Общая площадь",
  "random-forest.validation": "Валидация",
  "random-forest.validation.split": "Процент разбиения",
  "random-forest.validation.seed": "Случайное зерно",
  "random-forest.validation.split-points": "Разбить обучающий набор",
  "random-forest.choose-validation-points": "Валидационный набор",
  "random-forest.validation.external": "Дополнительный набор",
  "random-forest.validation.cross_validation": "Кросс валидация",
  "random-forest.validation.render_mean": "Вывести среднее",
  "random-forest.validation.render_best": "Вывести лучшее",
  "random-forest.validation.use-by-default": "Использовать по умолочанию",
  "random-forest.validation.best": "Лучшее",
  "random-forest.validation.mean": "Среднее",
  "survival.title": "Выживаемость",
  "survival.file": "Файл для MARK",
  "survival.nocc": "Количество дней наблюдений",
  "maxent.title": "Maxent",
  "maxent.background_points": "Фоновые точки",
  "maxent.generate_background_points": "Генерировать фоновые точки",
  "maxent.background_points_count": "Количество фоновых точек",

  "migrations.title": "Миграции",
  "migrations.add-files": "Добавить файлы",
  "migrations.add-migration": "Добавить миграцию",
  "migrations.add-season": "Добавить",
  "migrations.auto-migration": "Разбить на сезоны автоматически",
  "migrations.parse-files": "Загрузить",
  "migrations.winter": "Зима",
  "migrations.autumn": "Осень",
  "migrations.summer": "Лето",
  "migrations.spring": "Весна",
  "migrations.point-index": "Индекс",
  "migrations.point-date": "Дата",
  "migrations.point-altitude": "Высота",
  "migrations.point-info": "Информация с трекера",
  "migrations.selected-migrations": "Выбрано миграций",
  "migrations.generate": "Сгенерировать треки",
  "migrations.generate-count-label": "Количество треков для генерации",
  "migrations.generated-tracks": "Сгенерированные треки",
  "migrations.generated-tracks-total": "Треков",
  "migrations.generated-areas": "Сгенерированные области",
  "migrations.indexed-areas": "Обработанные области",
  "migrations.vulnerability": "Рассчет угроз",
  "migrations.vulnerability-selecred-areas": "Областей для расчета",
  "migrations.vulnerability-config": "Конфиг",
  "migrations.vulnerability-errors-in-config": "Ошибки в конфиге",
  "migrations.vulnerability-run": "Рассчитать угрозы",
  "migrations.area-use-in-vulnerability": "Использовать при рассчете угроз",
  "migrations.area-statistics": "Детальная статистика",
  "migrations.area-index": "Индекс",
  "migrations.area-real-tracks": "Уникальных пролетов",
  "migrations.area-total-tracks": "Сгенерированных треков",

  "migrations.area-heights": "Высота (м)",
  "migrations.area-real-heights": "Реальных точек",
  "migrations.area-generated-heights": "Сгенерированных точек",
  "migrations.add-habitat-areas": "Добавить области обитания",

  "grid-vulnerability.title": "Расчет угроз",
  "grid-vulnerability.grid.map": "Выберите сеть",
  "grid-vulnerability.density": "Плотность",
  "grid-vulnerability.density.type": "Выберите тип",
  "grid-vulnerability.density.simple-area": "Область обитания",
  "grid-vulnerability.density.simple-area.presence_area":
    "Выберите область обитания",
  "grid-vulnerability.density.points": "Точки",
  "grid-vulnerability.density.points.points": "Точки",
  "grid-vulnerability.density.points.outside_buffers_count":
    "Птиц за пределами буфферов",
  "grid-vulnerability.density.points.default_buffer": "Буффер по умолчанию",
  "grid-vulnerability.density.points.presence_area": "Область обитания",
  "grid-vulnerability.density.points.count": "Численность",

  "grid-vulnerability.bird-flight": "Параметры полета птицы",

  "grid-vulnerability.points.flight_heights.average.percentAtRotorHeight":
    "Процент на высоте ротора",

  "grid-vulnerability.points.bird_speed.average.speed": "Средняя скорость",

  "grid-vulnerability.points.counts.table": "Из таблицы",
  "grid-vulnerability.points.counts.breeding": "По размножению",
  "grid-vulnerability.points.counts.breeding.breeding_pairs":
    "Размножающихся пар",
  "grid-vulnerability.points.counts.breeding.successful_pairs": "Успешных пар",
  "grid-vulnerability.points.counts.breeding.juvenils_per_pair":
    "Слетков на пару",
  "grid-vulnerability.points.counts.breeding.subadults_ratio":
    "Процент незрелых",
  "grid-vulnerability.points.counts.breeding.percent_per_month":
    "Доля по месяцам",

  "grid-vulnerability.windfarm.set-default": "Заполнить по умолчанию",
  "grid-vulnerability.windfarm": "Данные ветряной фермы",
  "grid-vulnerability.windfarm.blades": "blades",
  "grid-vulnerability.windfarm.radius": "radius",
  "grid-vulnerability.windfarm.pitch": "pitch",
  "grid-vulnerability.windfarm.chord": "chord",
  "grid-vulnerability.windfarm.bladeForm": "Форма лопасти",
  "grid-vulnerability.windfarm.bladeForm.radius": "Радиусы формы лопасти",
  "grid-vulnerability.windfarm.bladeForm.chords": "Хорды формы лопасти",
  "grid-vulnerability.windfarm.omega": "omega",
  "grid-vulnerability.windfarm.numberOfTurbines": "numberOfTurbines",
  "grid-vulnerability.windfarm.hubheight": "hubheight",
  "grid-vulnerability.windfarm.offset": "offset",
  "grid-vulnerability.windfarm.timeOfWorkPerMonth": "Доля работы по месяцам",

  "grid-vulnerability.bird_config": "Данные птицы",
  "grid-vulnerability.bird_config.length": "Длина тела",
  "grid-vulnerability.bird_config.wingspan": "Размах крыла",
  "grid-vulnerability.bird_config.nocturnalActivity": "Ночная активность",

  "grid-vulnerability.bird_config.flight_type": "Тип полета",
  "grid-vulnerability.bird_config.flight_type.flapping": "Машущий",
  "grid-vulnerability.bird_config.flight_type.gliding": "Планирующий",

  "grid-vulnerability.flight_locations": "Летное время",
  "grid-vulnerability.flight_locations.average": "Среднее",
  "grid-vulnerability.flight_locations.average.lightDayPercent":
    "Процент активности от солнечного дня",

  "registration.title": "Регистрация",
  "registration.email-label": "Email",
  "registration.name-label": "Имя",
  "registration.name-placeholder": "Полное имя",
  "registration.password-label": "Пароль",
  "registration.password-placeholder": "Минимум 8 символов",
  "registration.password-repeat-placeholder": "Повторите пароль",
  "registration.password-repeat-label": "Повторите пароль",
  "registration.comment-label": "Дополнительная информация",
  "registration.comment-placeholder":
    "Например, дополнительные контактные данные",

  "registration.register-button": "Зарегистрироваться",
  "registration.login-link": "Войти",
  "login.title": "Вход",
  "login.password-placeholder": "Введите ваш пароль",
  "login.login-button": "Войти",
  "login.registration-link": "Зарегистрироваться",

  "cabinet-results.download": "Скачать",
  "cabinet-results.view-logs": "Посмотреть логи",
  "cabinet-results.expires-in": "Истекает: ",
  "cabinet-results.non-expires": "Не истекает",

  "cabinet.navigation-info": "Инфо",
  "cabinet.navigation-results": "Результаты",
  "cabinet.navigation-logout": "Выйти",
};
