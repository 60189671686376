import useSWR, { Fetcher } from "swr";
import { api } from "@/api";
import { PaginatedResult } from "@rrrcn/common-types/strapi/models/Pagination";
import { MapSpatialGrid } from "@rrrcn/common-types/strapi/custom-models/MapSpatialGrid";

//TODO MAYBE ADD PAGINATION LATER
export type VulnerabilityGridsKey = ["vulnerability-grids"];
export const getVulnerabilityGridsKey = (): VulnerabilityGridsKey => [
  "vulnerability-grids",
];
export const vulnerabilityGridsFetcher: Fetcher<
  PaginatedResult<MapSpatialGrid> | null,
  VulnerabilityGridsKey
> = ([_]) => {
  return api.map.getApiMapSpatialGrids().then((data) => data.data);
};
export const useVulnerabilityGridsSwr = () => {
  return useSWR<PaginatedResult<MapSpatialGrid> | null, VulnerabilityGridsKey>(
    getVulnerabilityGridsKey(),
    vulnerabilityGridsFetcher,
    { keepPreviousData: true }
  );
};
