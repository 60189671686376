import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import { GridVulnerabilityDensityPointsForm } from "@/features/analysis/vulnerability/components/density/components/points";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { GridVulnerabilityDensitySimpleAreaForm } from "@/features/analysis/vulnerability/components/density/components/area";

export const GridVulnerabilityDensityForm = ({ name }: { name: string }) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <Accordion className={"common__card_blue"}>
      <AccordionSummary
        className={`common__card common__card_${
          (touched[`density`] || submitCount) && errors?.density
            ? "error"
            : "blue"
        }`}
      >
        <Typography>{strings["grid-vulnerability.density"]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Select
          onClick={(e) => e.stopPropagation()}
          size={"small"}
          value={config?.type}
          onChange={({ target: { value } }) =>
            setConfig({ type: value as "points" | "area" | "ranged_areas" })
          }
        >
          <MenuItem value={"area"}>
            {strings["grid-vulnerability.density.simple-area"]}
          </MenuItem>
          <MenuItem value={"points"}>
            {strings["grid-vulnerability.density.points"]}
          </MenuItem>
          {/*<MenuItem value={"ranged_areas"}>*/}
          {/*    {strings["grid-vulnerability.points.counts.table"]}*/}
          {/*</MenuItem>*/}
        </Select>
        {config?.type === "area" && (
          <GridVulnerabilityDensitySimpleAreaForm name={name} />
        )}
        {config?.type === "points" && (
          <GridVulnerabilityDensityPointsForm name={name} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
