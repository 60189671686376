import * as yup from "yup";
import { GisHabitatAreaVulnerabilityCalculationConfig } from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import { GridVulnerabilityDensityInputSchema } from "@/features/analysis/vulnerability/schema/density";
import { GridVulnerabilityFlightHeightsInputSchema } from "@/features/analysis/vulnerability/schema/flight_heights";
import { GridVulnerabilityFlightLocationsInputSchema } from "@/features/analysis/vulnerability/schema/flight_locations";
import { GridVulnerabilityBirdConfigInputSchema } from "@/features/analysis/vulnerability/schema/bird_config";
import { GridVulnerabilityBirdSpeedInputSchema } from "@/features/analysis/vulnerability/schema/bird_speed";
import { GridVulnerabilityWindfarmConfigInputSchema } from "@/features/analysis/vulnerability/schema/windfarm_config";

export const GridVulnerabilityInputSchema: yup.Schema<
  GisHabitatAreaVulnerabilityCalculationConfig<File>
> = yup.object({
  density: GridVulnerabilityDensityInputSchema,
  bird_speed: GridVulnerabilityBirdSpeedInputSchema,
  flight_heights: GridVulnerabilityFlightHeightsInputSchema,
  flight_locations: GridVulnerabilityFlightLocationsInputSchema,
  bird_config: GridVulnerabilityBirdConfigInputSchema,
  updownProportion: yup.number().required(),
  windfarm_config: GridVulnerabilityWindfarmConfigInputSchema,
  grid: yup.object().shape({
    type: yup.mixed<"map">().oneOf(["map"]).required(),
    id: yup.number().required(),
  }),
});
