import { MonthsKey } from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import { CommonPerMonthNumberInputContainer } from "./style";
import { TextField } from "@mui/material";
import React from "react";

export type CommonPerMonthNumberInputProps = {
  value: Partial<{ [p in MonthsKey]: number }>;
  onChange: (value: Partial<{ [p in MonthsKey]: number }>) => void;
};
const fields = new Array(12).fill(0);
export const CommonPerMonthNumberInput = ({
  value,
  onChange,
}: CommonPerMonthNumberInputProps) => {
  return (
    <CommonPerMonthNumberInputContainer>
      {fields.map((_, index) => (
        <TextField
          key={index}
          margin={"dense"}
          size={"small"}
          value={value[`${index}` as MonthsKey]}
          onChange={({ target: { value: _value } }) =>
            onChange({
              ...value,
              [`${index}` as MonthsKey]: _value,
            })
          }
          label={""}
        />
      ))}
    </CommonPerMonthNumberInputContainer>
  );
};
