import * as yup from "yup";
import { lazy } from "yup";
import { GeometryInputSchema } from "@/features/analysis/common/geometry-validations";
import {
  GisHabitatAreaVulnerabilityCalculationConfig,
  GisHabitatAreaVulnerabilityCalculationConfigDensityPoints,
  GisHabitatAreaVulnerabilityCalculationConfigDensitySimpleArea,
} from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import { GridVulnerabilityDensityPointsCountInputSchema } from "./counts";

export const GridVulnerabilityDensityPointsInputSchema: yup.Schema<
  GisHabitatAreaVulnerabilityCalculationConfigDensityPoints<File>
> = yup.object({
  type: yup.mixed<"points">().required().oneOf(["points"]),
  points: GeometryInputSchema(),
  presence_area: GeometryInputSchema(),
  outside_buffers_count: yup.number().required(),
  default_buffer: yup.number().required(),
  count: GridVulnerabilityDensityPointsCountInputSchema,
});

export const GridVulnerabilityDensitySimpleAreaInputSchema: yup.Schema<
  GisHabitatAreaVulnerabilityCalculationConfigDensitySimpleArea<File>
> = yup.object({
  type: yup.mixed<"area">().required().oneOf(["area"]),
  presence_area: GeometryInputSchema(),
  count: GridVulnerabilityDensityPointsCountInputSchema,
});

export const GridVulnerabilityDensityInputSchema = lazy<
  yup.Schema<GisHabitatAreaVulnerabilityCalculationConfig<File>["density"]>
>((value) => {
  switch (value?.type) {
    case "points": {
      return GridVulnerabilityDensityPointsInputSchema;
    }
    default:
    case "area": {
      return GridVulnerabilityDensitySimpleAreaInputSchema;
    }
    // case "ranged_areas": {
    // }
  }
});
