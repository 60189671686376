import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import { GridVulnerabilityDensityPointsCountsForm } from "@/features/analysis/vulnerability/components/density/components/points/counts";
import { GeometryInput } from "@/components/geometry-inputs/geometry-input";
import { TextField, Typography } from "@mui/material";
import React from "react";
import { CommonPaper } from "@/components/common/common";

export const GridVulnerabilityDensityPointsForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <>
      <CommonPaper>
        <Typography>
          {strings["grid-vulnerability.density.points.presence_area"]}
        </Typography>
        <GeometryInput
          onChange={(geom) => {
            setConfig({
              ...config,
              type: "points",
              presence_area: geom,
            });
          }}
          value={config?.presence_area}
          type={"polygon" as google.maps.drawing.OverlayType.POLYGON}
        />
      </CommonPaper>
      <CommonPaper>
        <Typography>
          {strings["grid-vulnerability.density.points.points"]}
        </Typography>

        <GeometryInput
          onChange={(geom) => {
            setConfig({
              ...config,
              type: "points",
              points: geom,
            });
          }}
          value={config?.points}
          type={"marker" as google.maps.drawing.OverlayType.MARKER}
        />
      </CommonPaper>
      <CommonPaper>
        <TextField
          margin={"dense"}
          size={"small"}
          error={
            !!(
              (touched[`${name}.default_buffer`] || submitCount) &&
              errors?.default_buffer
            )
          }
          value={config?.default_buffer || null}
          onChange={({ target: { value } }) =>
            setConfig({
              ...config,
              type: "points",
              default_buffer: value,
            })
          }
          label={strings["grid-vulnerability.density.points.default_buffer"]}
        />
        <TextField
          margin={"dense"}
          size={"small"}
          error={
            !!(
              (touched[`${name}.outside_buffers_count`] || submitCount) &&
              errors?.outside_buffers_count
            )
          }
          value={config?.outside_buffers_count || null}
          onChange={({ target: { value } }) =>
            setConfig({
              ...config,
              type: "points",
              outside_buffers_count: value,
            })
          }
          label={
            strings["grid-vulnerability.density.points.outside_buffers_count"]
          }
        />
      </CommonPaper>
      <CommonPaper>
        <GridVulnerabilityDensityPointsCountsForm name={`${name}.count`} />
      </CommonPaper>
    </>
  );
};
