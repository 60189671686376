import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";

import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CommonPerMonthNumberInput } from "@/features/analysis/vulnerability/components/common/per-month-number-input";

export const GridVulnerabilityDensityPointsCountsForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <>
      <Typography>
        {strings["grid-vulnerability.density.points.count"]}
      </Typography>
      <Select
        onClick={(e) => e.stopPropagation()}
        size={"small"}
        value={config?.type}
        onChange={({ target: { value } }) =>
          setConfig({ type: value as "breeding" | "table" })
        }
      >
        <MenuItem value={"breeding"}>
          {strings["grid-vulnerability.points.counts.breeding"]}
        </MenuItem>
        <MenuItem value={"table"}>
          {strings["grid-vulnerability.points.counts.table"]}
        </MenuItem>
      </Select>
      {config?.type === "breeding" && (
        <GridVulnerabilityDensityPointsBreedingCountsForm name={name} />
      )}
      {config?.type === "table" && (
        <GridVulnerabilityDensityPointTableCountsForm name={name} />
      )}
    </>
  );
};
export const GridVulnerabilityDensityPointsBreedingCountsForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <>
      <TextField
        margin={"dense"}
        size={"small"}
        error={
          !!(
            (touched[`${name}.breeding_pairs`] || submitCount) &&
            errors?.breeding_pairs
          )
        }
        value={config?.data?.breeding_pairs || null}
        onChange={({ target: { value } }) =>
          setConfig({
            type: "breeding",
            data: { ...config.data, breeding_pairs: value },
          })
        }
        label={
          strings["grid-vulnerability.points.counts.breeding.breeding_pairs"]
        }
      />
      <TextField
        margin={"dense"}
        size={"small"}
        error={
          !!(
            (touched[`${name}.successful_pairs`] || submitCount) &&
            errors?.successful_pairs
          )
        }
        value={config?.data?.successful_pairs || null}
        onChange={({ target: { value } }) =>
          setConfig({
            type: "breeding",
            data: { ...config.data, successful_pairs: value },
          })
        }
        label={
          strings["grid-vulnerability.points.counts.breeding.successful_pairs"]
        }
      />
      <TextField
        margin={"dense"}
        size={"small"}
        error={
          !!(
            (touched[`${name}.juvenils_per_pair`] || submitCount) &&
            errors?.juvenils_per_pair
          )
        }
        value={config?.data?.juvenils_per_pair || null}
        onChange={({ target: { value } }) =>
          setConfig({
            type: "breeding",
            data: { ...config.data, juvenils_per_pair: value },
          })
        }
        label={
          strings["grid-vulnerability.points.counts.breeding.juvenils_per_pair"]
        }
      />
      <TextField
        margin={"dense"}
        size={"small"}
        error={
          !!(
            (touched[`${name}.subadults_ratio`] || submitCount) &&
            errors?.subadults_ratio
          )
        }
        value={config?.data?.subadults_ratio || null}
        onChange={({ target: { value } }) =>
          setConfig({
            type: "breeding",
            data: { ...config.data, subadults_ratio: value },
          })
        }
        label={
          strings["grid-vulnerability.points.counts.breeding.subadults_ratio"]
        }
      />

      <Accordion className={"common__card_blue"}>
        <AccordionSummary>
          <Typography>
            {
              strings[
                "grid-vulnerability.points.counts.breeding.percent_per_month"
              ]
            }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CommonPerMonthNumberInput
            value={config.data?.percent_per_month || {}}
            onChange={(value) =>
              setConfig({
                type: "breeding",
                data: { ...config.data, percent_per_month: value },
              })
            }
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const GridVulnerabilityDensityPointTableCountsForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <>
      <Input
        error={!!((touched[name] || submitCount) && errors?.file)}
        inputProps={{
          accept: "text/csv",
        }}
        size={"small"}
        type={"file"}
        onChange={({
          target: { files, form },
        }: React.ChangeEvent<HTMLInputElement>) => {
          setConfig({
            type: "table",
            file: files?.[0],
          });
        }}
      />
    </>
  );
};
