import { useField, useFormikContext } from "formik";
import { useTranslations } from "@/utils/translations";
import { CommonPaper } from "@/components/common/common";
import { Typography } from "@mui/material";
import { GeometryInput } from "@/components/geometry-inputs/geometry-input";
import { GridVulnerabilityDensityPointsCountsForm } from "@/features/analysis/vulnerability/components/density/components/points/counts";
import React from "react";

export const GridVulnerabilityDensitySimpleAreaForm = ({
  name,
}: {
  name: string;
}) => {
  const { setFieldValue, touched, submitCount } = useFormikContext<any>();
  const [{ value: config }, fieldMeta, { setValue: setConfig }] =
    useField(name);
  const errors = fieldMeta.error as any;
  const strings = useTranslations();
  return (
    <>
      <CommonPaper>
        <Typography>
          {strings["grid-vulnerability.density.simple-area.presence_area"]}
        </Typography>
        <GeometryInput
          onChange={(geom) => {
            setConfig({
              ...config,
              type: "area",
              presence_area: geom,
            });
          }}
          value={config?.presence_area}
          type={"polygon" as google.maps.drawing.OverlayType.POLYGON}
        />
      </CommonPaper>
      <CommonPaper>
        <GridVulnerabilityDensityPointsCountsForm name={`${name}.count`} />
      </CommonPaper>
    </>
  );
};
