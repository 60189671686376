import { GisHabitatAreaVulnerabilityCalculationConfig } from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import * as yup from "yup";

export const GridVulnerabilityBirdConfigInputSchema: yup.Schema<
  GisHabitatAreaVulnerabilityCalculationConfig["bird_config"]
> = yup.object({
  flight_type: yup
    .mixed<"flapping" | "gliding">()
    .required()
    .oneOf(["flapping", "gliding"]),
  length: yup.number().required(),
  wingspan: yup.number().required(),
  nocturnalActivity: yup.number().required(),
});
