import { useTranslations } from "@/utils/translations";
import { _useSendAnalysis } from "@/features/analysis/common/utils";
import { FormikProvider, useFormik } from "formik";
import { GisHabitatAreaVulnerabilityCalculationConfig } from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import { GridVulnerabilityDensityForm } from "@/features/analysis/vulnerability/components/density";
import { GridVulnerabilityFlightLocationsForm } from "src/features/analysis/vulnerability/components/flight_locations";
import { GridVulnerabilitySpeedForm } from "./components/speed";
import { GridVulnerabilityGridForm } from "@/features/analysis/vulnerability/components/grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import { GridVulnerabilityBirdConfigForm } from "@/features/analysis/vulnerability/components/bird-config";
import { GridVulnerabilityWindfarmConfigForm } from "@/features/analysis/vulnerability/components/windfarm-config";
import { FormTextField } from "@/components/common/form-field";
import { GridVulnerabilityInputSchema } from "@/features/analysis/vulnerability/schema";
import { DeepPartial } from "@/utils/types";
import { GridVulnerabilityFlightHeightsForm } from "@/features/analysis/vulnerability/components/flight_heights";

export const GridVulnerabilityForm = () => {
  const strings = useTranslations();
  const { onSend } = _useSendAnalysis("grid-vulnerability");
  const formik = useFormik<
    DeepPartial<GisHabitatAreaVulnerabilityCalculationConfig<File>>
  >({
    initialValues: {
      grid: {
        type: "map",
      },
      density: {
        type: "area",
      },
      bird_speed: {
        type: "average",
      },
      flight_heights: {
        type: "average",
      },
      flight_locations: {
        type: "average",
      },
      bird_config: {
        flight_type: "gliding",
      },
    },
    validationSchema: GridVulnerabilityInputSchema,
    onSubmit: (data) => {
      // console.log(data);
      onSend(data);
    },
  });
  const { submitForm, values } = formik;

  return (
    <FormikProvider value={formik}>
      <GridVulnerabilityGridForm name={"grid"} />
      <GridVulnerabilityDensityForm name={"density"} />

      <Accordion className={"common__card_blue"}>
        <AccordionSummary>
          <Typography>{strings["grid-vulnerability.bird-flight"]}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GridVulnerabilitySpeedForm name={"bird_speed"} />
          <GridVulnerabilityFlightLocationsForm name={"flight_locations"} />
          <FormTextField
            name={"updownProportion"}
            label={"updownProportion"}
            mapValue={(value) => value}
          />
          <GridVulnerabilityFlightHeightsForm name={"flight_heights"} />
        </AccordionDetails>
      </Accordion>
      <GridVulnerabilityBirdConfigForm name={"bird_config"} />
      <GridVulnerabilityWindfarmConfigForm name={"windfarm_config"} />

      <Button
        onClick={() => {
          submitForm();
        }}
      >
        {strings["data-extraction.get-result"]}
      </Button>
    </FormikProvider>
  );
};
