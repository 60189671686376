import { GisHabitatAreaVulnerabilityCalculationConfig } from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import * as yup from "yup";

export const GridVulnerabilityWindfarmConfigInputSchema: yup.Schema<
  GisHabitatAreaVulnerabilityCalculationConfig["windfarm_config"]
> = yup.object({
  blades: yup.number().required(),
  radius: yup.number().required(),
  pitch: yup.number().required(),
  chord: yup.number().required(),
  bladeForm: yup
    .array()
    .of(
      yup.tuple([yup.number().required(), yup.number().required()]).required()
    )
    .required(),
  omega: yup.number().required(),
  numberOfTurbines: yup.number().required(),
  hubheight: yup.number().required(),
  offset: yup.number().required(),
  timeOfWorkPerMonth: yup
    .array()
    .of(yup.number().required())
    .required()
    .length(12),
});
