import {
  GisHabitatAreaVulnerabilityCalculationConfigCountBreedingType,
  GisHabitatAreaVulnerabilityCalculationConfigCountTableType,
  GisHabitatAreaVulnerabilityCalculationConfigPointsCountType,
} from "@rrrcn/common-types/admin/api/gis/grid-vulnerability";
import * as yup from "yup";
import { lazy } from "yup";

export const GridVulnerabilityDensityPointsCountBreedingInputSchema: yup.Schema<GisHabitatAreaVulnerabilityCalculationConfigCountBreedingType> =
  yup.object({
    type: yup.mixed<"breeding">().required().oneOf(["breeding"]),
    data: yup.object({
      breeding_pairs: yup.number().required(),
      successful_pairs: yup.number().required(),
      juvenils_per_pair: yup.number().required(),
      subadults_ratio: yup.number().required(),
      percent_per_month: yup.object({
        0: yup.number().required().default(0),
        1: yup.number().required().default(0),
        2: yup.number().required().default(0),
        3: yup.number().required().default(0),
        4: yup.number().required().default(0),
        5: yup.number().required().default(0),
        6: yup.number().required().default(0),
        7: yup.number().required().default(0),
        8: yup.number().required().default(0),
        9: yup.number().required().default(0),
        10: yup.number().required().default(0),
        11: yup.number().required().default(0),
      }),
    }),
  });

export const GridVulnerabilityDensityPointsCountTableInputSchema: yup.Schema<
  GisHabitatAreaVulnerabilityCalculationConfigCountTableType<File>
> = yup.object({
  type: yup.mixed<"table">().required().oneOf(["table"]),
  file: yup.mixed<File>().required(),
});

export const GridVulnerabilityDensityPointsCountInputSchema = lazy<
  yup.Schema<GisHabitatAreaVulnerabilityCalculationConfigPointsCountType<File>>
>((value) => {
  switch (value?.type) {
    case "breeding": {
      return GridVulnerabilityDensityPointsCountBreedingInputSchema;
    }
    default:
    case "table": {
      return GridVulnerabilityDensityPointsCountTableInputSchema;
    }
  }
});
